import {Component, OnDestroy, OnInit} from '@angular/core';
import {TransactionModel} from '../../../shared/model/transaction.model';
import {take} from 'rxjs/operators';
import {MessageService} from '../../../shared/services/messages/message.service';
import {TransactionService} from '../../../shared/services/base/transaction.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-statements',
  templateUrl: './statements.component.html',
  styleUrls: ['./statements.component.scss']
})
export class StatementsComponent implements OnInit, OnDestroy {

  loading = false;
  values: TransactionModel[] = [];
  transactionChange: Subscription;

  constructor(
    private readonly messageService: MessageService,
    private readonly transactionService: TransactionService
  ) {
  }

  ngOnInit(): void {
    this.transactionChange = TransactionService.transactionChange.subscribe({
      next: () => this.load()
    });

    this.load();
  }

  ngOnDestroy(): void {
    if (this.transactionChange) {
      this.transactionChange.unsubscribe();
    }
  }

  private load(): void {
    this.loading = true;
    this.transactionService.getRecentStatement().pipe(
      take<TransactionModel[]>(1)
    ).subscribe({
      next: transactions => {
        this.values = transactions || [];
        this.loading = false;
      },
      error: error => {
        this.loading = false;
        this.messageService.addDanger(error);
      }
    });
  }
}
