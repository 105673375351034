import {Component, OnDestroy} from '@angular/core';
import {MessageService} from '../../services/messages/message.service';
import {Message} from '../../services/messages/message';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-alert-message',
  templateUrl: './alert-message.component.html',
  styleUrls: ['./alert-message.component.scss']
})
export class AlertMessageComponent implements OnDestroy {

  public items: Message[] = [];

  private subscription: Subscription;

  constructor(private messageService: MessageService) {
    this.subscription = messageService.messageEventEmitter.subscribe(message => {
      this.items.push(message);

      setTimeout(() => {
        this.deleteMessage(message);
      }, 6000);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteMessage(item: Message) {
    const index = this.items.indexOf(item, 0);
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }
}
