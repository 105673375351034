import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable, Subject} from 'rxjs';
import {TransactionModel} from '../../model/transaction.model';
import {BalanceModel} from '../../model/balance.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionService extends BaseService<TransactionModel, number> {

  static readonly transactionChange = new Subject<void>();

  constructor(protected http: HttpClient) {
    super(http, '/v1/transactions');
  }

  getBalance(): Observable<number> {
    return this.http.get<number>(`${this.baseURL}/balance`);
  }

  getBankStatement(period: Date, accountId: number = null): Observable<BalanceModel> {
    if (accountId) {
      return this.http.post<BalanceModel>(`${this.baseURL}/bank-statement/${accountId}`, period);
    }

    return this.http.post<BalanceModel>(`${this.baseURL}/bank-statement`, period);
  }

  getRecentStatement(): Observable<TransactionModel[]> {
    return this.http.get<TransactionModel[]>(`${this.baseURL}/recent`);
  }
}
