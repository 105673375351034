<dialog #modal [class.visually-hidden]="!display$" aria-labelledby="alertTitle" class="modal fade" id="add-funds-modal"
        style="z-index: 101" tabindex="-1">
  <div class="modal-dialog mini-modal" style="max-width: 800px">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="alertTitle">Add funds</h5>
        <button (click)="close()" aria-label="Close" class="btn-close" data-dismiss="modal" type="button">
          <span class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form #form="ngForm" aria-labelledby="alertTitle">
          <div class="row">
            <div class="col-lg-3">
              <label class="form-label" for="value">
                Value <span class="mandatory">*</span>
              </label>
              <p-inputNumber
                #v="ngModel" [(ngModel)]="value"
                [ngClass]="{ 'is-invalid': v.errors && v.touched }"
                currency="USD" id="value" inputStyleClass="form-control"
                locale="en-US" max="999999" min="0.01" mode="currency"
                name="value" placeholder="$0.00" required
              ></p-inputNumber>
            </div>
            <div [class.col-lg-7]="idCard !== 0" [class.col-lg-9]="idCard === 0">
              <label class="form-label" for="card">
                Credit card <span class="mandatory">*</span>
              </label>
              <p-dropdown
                (ngModelChange)="changeCard()" [(ngModel)]="idCard" [filter]="true" [options]="cards" id="card"
                name="card" optionLabel="name" optionValue="id" placeholder="Select a credit card" required
              ></p-dropdown>
            </div>
            <div *ngIf="idCard != 0" class="col-lg-2">
              <label class="form-label" for="cvc" ngbTooltip="Card Validation/Security Code">
                CVC <span class="mandatory">*</span>
              </label>
              <input #cv="ngModel" [(ngModel)]="cvc" [ngClass]="{ 'is-invalid':cv.errors && cv.touched }" ccCVC
                     class="form-control" id="cvc" name="cvc" required type="tel">
            </div>
            <div *ngIf="idCard == 0">
              <form [formGroup]="cardForm" aria-label="New card info">
                <div class="row">
                  <div class="col-lg-6">
                    <label class="form-label" for="name">
                      Name <span class="mandatory">*</span>
                    </label>
                    <input
                      [ngClass]="{ 'is-invalid': name.errors && name.touched }" appAutoFocus
                      class="form-control" formControlName="name" id="name" maxlength="255" required type="text">
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="number">
                      Credit card number <span class="mandatory">*</span>
                    </label>
                    <input
                      [ngClass]="{ 'is-invalid': number.errors && number.touched }"
                      autocomplete="cc-number" ccNumber class="form-control" formControlName="number"
                      id="number" required type="text">
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label" for="cvv" ngbTooltip="Card Validation/Security Code">
                      CVC <span class="mandatory">*</span>
                    </label>
                    <input
                      [ngClass]="{ 'is-invalid':cvv.errors && cvv.touched }" ccCVC class="form-control"
                      formControlName="cvv"
                      id="cvv" required type="tel">
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label" for="expiration">
                      Expiration date <span class="mandatory">*</span>
                    </label>
                    <input
                      [ngClass]="{ 'is-invalid': (expiration.errors || !isExpirationValid()) && expiration.touched }"
                      autocomplete="cc-exp" ccExp class="form-control" formControlName="expiration" id="expiration"
                      placeholder="00 / 0000" required type="text">
                  </div>
                  <div class="col-lg-4">
                    <label class="form-label" for="defaultCard">
                      Default payment?
                    </label>
                    <div>
                      <input [value]="true" formControlName="defaultCard" id="defaultCard"
                             maxlength="4" type="checkbox">
                      <label class="ms-1" for="defaultCard">
                        Yes
                      </label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </form>
        <div class="modal-footer text-center center btn-spacing">
          <button
            (click)="purchase()" [disabled]="form.invalid || (idCard == 0 && cardForm.invalid)" aria-label="Close"
            class="btn btn-primary"
            data-dismiss="modal"
            type="button">
            Purchase
          </button>
          <button
            (click)="close()" aria-label="Close" class="btn btn-danger" data-dismiss="modal" type="button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</dialog>
