import {Component, OnInit} from '@angular/core';
import {TokenStorageService} from '../../services/auth/token-storage.service';
import {UserSessionService} from '../../services/auth/user-session.service';

@Component({
  selector: 'app-theme-bar',
  templateUrl: './theme-bar.component.html'
})
export class ThemeBarComponent implements OnInit {

  isLoggedIn = false;

  currentTheme: string;

  constructor(
    private userSessionService: UserSessionService,
    private tokenStorageService: TokenStorageService
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }

  ngOnInit(): void {
    this.changeTheme(this.userSessionService.theme);
  }

  changeTheme(theme: string): void {
    const body = document.querySelector('body');
    body.classList.remove('tema-branco', 'tema-azul', 'tema-preto');

    this.currentTheme = theme;
    body.classList.add(theme);

    this.userSessionService.theme = theme;

    document.querySelectorAll('.opcao-tema').forEach(x => {
      x.classList.remove('active');

      if (x.classList.contains(theme)) {
        x.classList.add('active');
      }
    });
  }

}
