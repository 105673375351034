<app-breadcrumb>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li aria-current="page" class="breadcrumb-item active">Contact</li>
    </ol>
  </nav>
</app-breadcrumb>

<div class="pagina-externa contato">
  <div class="row">
    <div class="col-lg-12 col-md-12 col-xs-12">
      <h1>Contact</h1>
      <p>Fill out the form below or contact us by phone or email.</p>
      <span class="chamada-home-2 mb-4">It will be a pleasure to serve you!</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-12 col-md-12 col-xs-12 pe-5">
      <form #f="ngForm" class="row" role="form">
        <div class="col-md-12">
          <label class="form-label" for="name">Name</label>
          <input #n="ngModel" [(ngModel)]="contact.name" [ngClass]="{ 'is-invalid': n.errors && n.touched }"
                 appAutoFocus autocomplete="name" class="form-control" id="name"
                 name="name" required type="text">
        </div>
        <div class="col-md-12">
          <label class="form-label" for="email">E-mail</label>
          <input #e="ngModel" [(ngModel)]="contact.email" [ngClass]="{ 'is-invalid': e.errors && e.touched }"
                 autocomplete="email" class="form-control" email id="email"
                 name="email" required type="email">
          <div *ngIf="e.errors?.email" class="invalid-feedback">
            The e-mail is not valid.
          </div>
        </div>
        <div class="col-12">
          <label class="form-label" for="message">Message</label>
          <textarea
            #m="ngModel" [(ngModel)]="contact.message" [ngClass]="{ 'is-invalid': m.errors && m.touched }"
            autocomplete="off" class="form-control"
            id="message" name="message" required rows="3"
          ></textarea>
        </div>
        <div class="col-12">
          <button
            (click)="send(f)" [disabled]="!f.form.valid" aria-label="Send" class="btn btn-primary"
            type="submit">
            Send
          </button>
        </div>
      </form>
    </div>
    <div class="col-xl-6 col-lg-12 col-md-12 col-xs-12 pt-4 ps-5 pb-5">
      <address>
        <h6>
          <fa-icon [icon]="mapMarkerAlt"></fa-icon>
          Address
        </h6>
        <p>1282 La Quinta Drive Unit 1, Orlando, FL 32809</p>
        <h6 class="pt-3">
          <fa-icon [icon]="phone"></fa-icon>
          Phone
        </h6>
        <p><a href="tel:+1(321)522-7341">+1 (386) 507-4293</a></p>
        <h6 class="pt-3">
          <fa-icon [icon]="whatsapp"></fa-icon>
          WhatsApp
        </h6>
        <p>
          <a href="https://wa.me/13865074293" rel="noreferrer noopener" target="_blank">+1 (386) 507-4293</a>
        </p>
        <h6 class="pt-3">
          <fa-icon [icon]="envelope"></fa-icon>
          E-mail
        </h6>
        <p><a href="mailto:contact@packboo.com">contact@packboo.com</a></p>
      </address>
    </div>
  </div>
</div>
