import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {HomeComponent} from './pages/home/home.component';
import {AlertMessageComponent} from './shared/components/alert-message/alert-message.component';
import {MessageService} from './shared/services/messages/message.service';
import {ConfirmMessageComponent} from './shared/components/confirm-message/confirm-message.component';
import {LoginComponent} from './pages/login/login.component';
import {authInterceptorProviders} from './shared/providers/auth.interceptor';
import {TokenStorageService} from './shared/services/auth/token-storage.service';
import {MyLoaderComponent} from './shared/components/my-loader/my-loader.component';
import {LoaderService} from './shared/services/loader.service';
import {loaderInterceptorProviders} from './shared/providers/loader.interceptor.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TrackingNumberComponent} from './pages/home/tracking-number/tracking-number.component';
import {AboutUsComponent} from './pages/home/about-us/about-us.component';
import {HelpComponent} from './pages/home/help/help.component';
import {ContactComponent} from './pages/home/contact/contact.component';
import {TermsComponent} from './pages/home/terms/terms.component';
import {AuthenticatedComponent} from './pages/home/authenticated/authenticated.component';
import {IsEllipsisActiveDirective} from './shared/directives/is-ellipsis-active.directive';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {StatisticsComponent} from './pages/home/statistics/statistics.component';
import {StatementsComponent} from './pages/home/statements/statements.component';
import {RECAPTCHA_V3_SITE_KEY} from 'ng-recaptcha';

@NgModule({
  declarations: [
    AlertMessageComponent,
    ConfirmMessageComponent,
    AppComponent,
    LoginComponent,
    HomeComponent,
    MyLoaderComponent,
    TrackingNumberComponent,
    AboutUsComponent,
    HelpComponent,
    ContactComponent,
    TermsComponent,
    AuthenticatedComponent,
    IsEllipsisActiveDirective,
    StatisticsComponent,
    StatementsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    MessageService,
    TokenStorageService,
    LoaderService,
    authInterceptorProviders,
    loaderInterceptorProviders,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: '6Ld819wiAAAAAHni-jaaW87_GfvD0ti-AVZckZwO'
    }
  ],
  exports: [
    IsEllipsisActiveDirective
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
