<h2 id="packageTracking">Recent transactions</h2>
<div class="table-responsive">
  <table aria-labelledby="title" class="table">
    <thead>
    <tr>
      <th class="dt" scope="col">Date</th>
      <th scope="col">Description</th>
      <th class="tp" scope="col">Type</th>
      <th class="ou" scope="col">Value</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="loading && values?.length == 0">
      <td class="notfound" colspan="4">Loading...</td>
    </tr>
    <tr *ngFor="let transaction of values">
      <td>{{transaction.creationDate | date: 'MM/dd/yyyy'}}</td>
      <td>
        {{transaction.description}}
        <a *ngIf="transaction.shipmentId" [routerLink]="['/shipment', transaction.shipmentId]"
           class="ms-1" ngbTooltip="Open the shipment details">
          <em class="bi bi-search"></em>
        </a>
      </td>
      <td>{{transaction.type?.name}}</td>
      <td>{{transaction.value | currency}}</td>
    </tr>
    <tr *ngIf="!values?.length && !loading">
      <td class="notfound" colspan="4">No records found.</td>
    </tr>
    </tbody>
  </table>
</div>
