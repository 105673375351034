import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  logEvent: Subject<boolean> = new Subject<boolean>();

  public isAuthenticated(): boolean {
    return this.getToken() != null;
  }

  public isAdmin(): boolean {
    const user = this.getUser();
    if (user?.roles) {
      return user.roles.includes('ROLE_ADMIN');
    }

    return false;
  }

  public isUser(): boolean {
    const user = this.getUser();
    if (user?.roles) {
      return user.roles.includes('ROLE_USER');
    }

    return false;
  }

  public isPersonal(): boolean {
    const user = this.getUser();
    if (user?.roles) {
      return user.roles.includes('ROLE_PERSONAL');
    }

    return false;
  }

  signOut(): void {
    window.sessionStorage.clear();
    this.logEvent.next(false);
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
    this.logEvent.next(true);
  }

  public getUser(): any {
    return JSON.parse(window.sessionStorage.getItem(USER_KEY));
  }
}
