import {Injectable} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LoaderService} from '../services/loader.service';
import {Router} from '@angular/router';
import {TokenStorageService} from '../services/auth/token-storage.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private requests: HttpRequest<any>[] = [];

  constructor(
    private readonly loaderService: LoaderService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly router: Router,
  ) {
  }

  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.endsWith('/balance') && !req.url.endsWith('/messages/unread') && !(req.url.includes('/messages/') && req.url.endsWith('/read'))) {
      this.requests.push(req);
      this.loaderService.isLoading.next(true);
    }
    return new Observable(observer => {
      const subscription = next.handle(req).subscribe({
        next: event => {
          if (event instanceof HttpResponse) {
            this.removeRequest(req);
            observer.next(event);
          }
        },
        error: error => {
          this.removeRequest(req);
          observer.error(error);

          if (error && error.status === 401 && !error.url.endsWith('/api/auth/sign-in')) {
            this.tokenStorageService.signOut();
            this.router.navigate(['/']).then();
          }
        },
        complete: () => {
          this.removeRequest(req);
          observer.complete();
        }
      });

      // remove request from queue when cancelled
      return () => {
        this.removeRequest(req);
        subscription.unsubscribe();
      };
    });
  }
}

export const loaderInterceptorProviders = [
  {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}
];
