import {Component, Input} from '@angular/core';
import {ObjetoRastroModel} from '../../model/objeto.rastro.model';
import {TrackingService} from '../../services/base/tracking.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-tracking-detail',
  templateUrl: './tracking-detail.component.html',
  styleUrls: ['./tracking-detail.component.scss']
})
export class TrackingDetailComponent {

  loading = false;
  event: ObjetoRastroModel;
  message: string;

  constructor(
    private trackingService: TrackingService
  ) {
  }

  @Input()
  set trackingNumber(trackingNumber: string) {
    if (trackingNumber) {
      this.event = null;
      this.loading = true;
      this.trackingService.find(trackingNumber).pipe(
        take(1)
      ).subscribe({
        next: object => {
          if (object.eventos?.length === 0) {
            this.message = `No events found for the package ${trackingNumber}`;
          } else {
            this.message = null;
          }

          this.event = object;
        },
        error: error => {
          this.message = error.error.message || 'The system is current unavailable, please try again later.';
        },
        complete: () => this.loading = false
      });
    }
  }

  closeMessage(): void {
    this.message = null;
  }
}
