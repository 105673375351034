import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {ContactModel} from '../../model/contact.model';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService<ContactModel, string> {

  protected constructor(protected http: HttpClient) {
    super(http, '/public/v1/contacts');
  }

}
