import {inject, NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {LoginComponent} from './pages/login/login.component';
import {TrackingNumberComponent} from './pages/home/tracking-number/tracking-number.component';
import {AboutUsComponent} from './pages/home/about-us/about-us.component';
import {HelpComponent} from './pages/home/help/help.component';
import {ContactComponent} from './pages/home/contact/contact.component';
import {TermsComponent} from './pages/home/terms/terms.component';
import {AuthenticatedComponent} from './pages/home/authenticated/authenticated.component';
import {PublicRouteGuard} from './shared/guards/public-route-guard';
import {CommonModule} from '@angular/common';
import {LoginRouteGuard} from './shared/guards/login-route-guard';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [() => inject(PublicRouteGuard).canActivate()]
  },
  {
    path: 'home',
    component: AuthenticatedComponent,
    canActivate: [() => inject(LoginRouteGuard).canActivate()]
  },
  {
    path: 'about',
    component: AboutUsComponent
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'tracking/:trackingNumber',
    component: TrackingNumberComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'balance',
    loadChildren: async () => (await import('./pages/balance/balance.module')).BalanceModule
  },
  {
    path: 'management',
    loadChildren: async () => (await import('./pages/management/management.module')).ManagementModule
  },
  {
    path: 'shipment',
    loadChildren: async () => (await import('./pages/shipments/shipments.module')).ShipmentsModule
  },
  {
    path: 'unit',
    loadChildren: async () => (await import('./pages/unit/unit.module')).UnitModule
  },
  {
    path: 'reports',
    loadChildren: async () => (await import('./pages/reports/reports.module')).ReportsModule
  },
  {
    path: 'services',
    loadChildren: async () => (await import('./pages/services/services.module')).ServicesModule
  },
  {
    path: 'profile',
    loadChildren: async () => (await import('./pages/profile/profile.module')).ProfileModule
  },
  {
    path: 'financial',
    loadChildren: async () => (await import('./pages/financial/financial.module')).FinancialModule
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true
    }),
    CommonModule
  ],
  declarations: [],
  exports: []
})
export class AppRoutingModule {
}
