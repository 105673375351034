import {Component, OnInit} from '@angular/core';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faMapMarkerAlt, faPhone} from '@fortawesome/free-solid-svg-icons';
import {TokenStorageService} from '../../../shared/services/auth/token-storage.service';
import {ContactModel} from '../../../shared/model/contact.model';
import {ContactService} from '../../../shared/services/base/contact.service';
import {take} from 'rxjs/operators';
import {MessageService} from '../../../shared/services/messages/message.service';
import {NgForm} from '@angular/forms';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html'
})
export class ContactComponent implements OnInit {

  mapMarkerAlt = faMapMarkerAlt;
  phone = faPhone;
  envelope = faEnvelope;
  whatsapp = faWhatsapp;
  isLoggedIn = false;

  contact: ContactModel;

  constructor(
    readonly tokenStorageService: TokenStorageService,
    private readonly contactService: ContactService,
    private readonly messageService: MessageService
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }

  ngOnInit(): void {
    this.contact = new ContactModel();
  }

  send(form: NgForm): void {
    this.contactService.create(this.contact).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.messageService.addSuccess('Message sent.');
        this.contact = new ContactModel();
        form.reset();
      },
      error: error => this.messageService.addDanger(error)
    });
  }
}
