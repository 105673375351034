<div [hidden]="!loading">

  <div class="loading-spinner">
    <div class="box">
      <div class="loading">
        <svg class="spinner" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
        </svg>
      </div>
      <span class="loading-message">
      Please wait...
    </span>
    </div>
  </div>
</div>
