import {Component, OnInit} from '@angular/core';
import {ShipmentPackageService} from '../../../shared/services/base/shipment-package.service';
import {PackageStatisticsModel} from '../../../shared/model/package-statistics.model';
import {take} from 'rxjs/operators';
import {MessageService} from '../../../shared/services/messages/message.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  total: number;
  mini: number;
  standard: number;
  express: number;
  modelListLoading = false;
  modelList: PackageStatisticsModel[];

  constructor(
    private readonly shipmentPackageService: ShipmentPackageService,
    private readonly messageService: MessageService
  ) {
  }

  ngOnInit(): void {
    this.modelListLoading = true;
    this.shipmentPackageService.findShipmentsToReceive().pipe(
      take(1)
    ).subscribe({
      next: values => {
        this.modelList = values;
        this.modelListLoading = false;

        this.total = values.map(x => {
          return x.accounts.reduce((previousValue, currentValue) => previousValue + currentValue.total, 0);
        }).reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);

        this.mini = values.map(x => {
          return x.accounts.reduce((previousValue, currentValue) => previousValue + currentValue.mini, 0);
        }).reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);

        this.standard = values.map(x => {
          return x.accounts.reduce((previousValue, currentValue) => previousValue + currentValue.standard, 0);
        }).reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);

        this.express = values.map(x => {
          return x.accounts.reduce((previousValue, currentValue) => previousValue + currentValue.express, 0);
        }).reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        }, 0);
      },
      error: error => {
        this.modelListLoading = false;
        this.messageService.addDanger(error);
      }
    });
  }
}
