import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PageModel} from '../model/page.model';

/**
 * Base class for CRUD services.
 *
 * @typeParam T - the entity class type.
 * @param <K> - the ID class type.
 */
export abstract class ReadonlyService<T, K> {

  protected baseURL;

  protected constructor(protected http: HttpClient, baseURL: string) {
    this.baseURL = environment.baseURL + baseURL;
  }

  findAll(): Observable<T[]> {
    return this.http.get<T[]>(this.baseURL);
  }

  findPaged(page: PageModel<T> = null): Observable<PageModel<T>> {
    const params = this.setPagination(new HttpParams(), page);

    return this.http.get<PageModel<T>>(this.baseURL, {
      params
    });
  }

  find(id: K): Observable<T> {
    return this.http.get<T>(`${this.baseURL}/${id}`);
  }

  protected setPagination(params: HttpParams, page: PageModel<any>): HttpParams {
    if (page?.pageable) {
      if (page.pageable.pageNumber) {
        params = params.append('page', String(page.pageable.pageNumber));
      }
      if (page.sortField) {
        params = params.append('sortField', page.sortField);
      }
      if (page.sortType) {
        params = params.append('sortType', page.sortType);
      }

      params = params.append('limit', String(page.pageable.pageSize));
    }

    return params;
  }
}
