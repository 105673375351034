<h2 id="packageStatistics">Package statistics</h2>

<div class="table-responsive">
  <table aria-labelledby="title" class="table">
    <thead>
    <tr>
      <th class="mini" scope="col">
        Mini
      </th>
      <th class="st" scope="col">
        Standard
      </th>
      <th class="ex" scope="col">
        Express
      </th>
      <th class="boxes" scope="col">
        Total
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="modelListLoading">
      <td class="notfound" colspan="6">Loading...</td>
    </tr>
    <tr *ngIf="!modelListLoading">
      <td class="mini">{{mini | number | emptyValue}}</td>
      <td class="st">{{standard | number | emptyValue}}</td>
      <td class="ex">{{express | number | emptyValue}}</td>
      <td class="boxes">{{total | number | emptyValue}}</td>
    </tr>
    </tbody>
  </table>
</div>

<div *ngFor="let statistics of modelList; let i = index" class="table-responsive">
  <table aria-labelledby="title" class="table">
    <thead>
    <tr>
      <th scope="col">
        {{statistics.company}}
      </th>
      <th class="mini" scope="col">
        Mini
      </th>
      <th class="st" scope="col">
        Standard
      </th>
      <th class="ex" scope="col">
        Express
      </th>
      <th class="boxes" scope="col">
        Total
      </th>
      <th class="op" scope="col"></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngIf="modelListLoading">
      <td class="notfound" colspan="6">Loading...</td>
    </tr>
    <tr *ngFor="let account of statistics.accounts">
      <td>{{account.account}}</td>
      <td class="mini">{{account.mini | number | emptyValue}}</td>
      <td class="st">{{account.standard | number | emptyValue}}</td>
      <td class="ex">{{account.express | number | emptyValue}}</td>
      <td class="boxes">{{account.total | number | emptyValue}}</td>
      <td>
        <button
          [queryParams]="{companyId: statistics.companyId, accountId: account.accountId}"
          aria-label="See the company shipments" class="btn btn-sm btn-danger"
          ngbTooltip="See the company shipments" routerLink="/shipment">
          <em class="bi bi-building"></em>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>
