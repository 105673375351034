import {AccountModel} from './account.model';
import {RoleModel} from './role.model';

export class UserModel {
  public id?: number;
  public name?: string;
  public email?: string;
  public emailHash?: string;
  public account?: AccountModel;
  public password?: string;
  public role?: RoleModel;
  public index?: number;
  public allowEmail?: boolean;
  public hasAffiliates?: boolean;

  public accountName?: string;
  public username?: string;
  public roles?: string[];

  public phoneNumber?: string;
  public senderName?: string;
  public tokenReCaptcha?: string;
  public address?: string;
  public addressNumber?: string;
  public addressComplement?: string;
  public addressZipCode?: string;
  public cityName?: string;
  public state?: string;
}
