import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ReadonlyService} from '../readonly.service';
import {ObjetoRastroModel} from '../../model/objeto.rastro.model';

@Injectable({
  providedIn: 'root'
})
export class TrackingService extends ReadonlyService<ObjetoRastroModel, string> {

  protected constructor(protected http: HttpClient) {
    super(http, '/public/v1/tracking');
  }

}
