import {Component} from '@angular/core';
import {TokenStorageService} from '../../services/auth/token-storage.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent {

  isLoggedIn = false;

  constructor(
    readonly tokenStorageService: TokenStorageService
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }
}
