<app-breadcrumb>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li aria-current="page" class="breadcrumb-item active">Home</li>
    </ol>
  </nav>
</app-breadcrumb>

<div class="pagina-interna home-user">
  <div class="row">
    <div *ngIf="isAdmin" class="col-md-6 col-sm-12">
      <div class="box-track">
        <app-statistics></app-statistics>
      </div>
    </div>
    <div *ngIf="!isAdmin" class="col-md-6 col-sm-12">
      <form aria-labelledby="uploadFile" role="form">
        <div class="col-sm-12">
          <div (dragenter)="dragEnter($event)" (dragover)="dragOver($event)" (drop)="drop($event)"
               class="drag-file" draggable="true">
            <h4 id="uploadFile">Upload a shipment file</h4>
            <div class="text-center">
              <em class="bi bi-cloud-upload icon-drag"></em>
              <br>
              Drag and drop the file here<br>or<br>
              <p>
                <input
                  #fileUpload (change)="onFileSelected($event)" accept=".csv" class="file-input" required
                  type="file">
                <button (click)="fileUpload.click()" aria-label="Click here to select one" class="btn btn-default">
                  Click here to select one
                </button>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-md-6 col-sm-12">
      <div class="box-track">
        <h2 id="packageTracking">Package tracking</h2>
        <form #form="ngForm" aria-labelledby="packageTracking" role="form">
          <input
            #t="ngModel" [(ngModel)]="trackingNumber" [ngClass]="{ 'is-invalid': t.errors && t.touched }"
            class="form-control search-field" maxlength="13" name="trackingNumber" placeholder="NX123456789BR"
            appAutoFocus required type="text">
          <button
            (click)="search()" [disabled]="form.invalid" aria-label="Search"
            class="btn btn-default search-button">
            Search
          </button>
          <app-tracking-detail [trackingNumber]="tracking"></app-tracking-detail>
        </form>
      </div>
      <div *ngIf="!isAdmin">
        <br/>
        <div class="box-track">
          <app-statements></app-statements>
        </div>
      </div>
    </div>
  </div>
</div>
