<div class="pagina-externa">
  <h1>About us</h1>
  <p>A parcel transport company totally focused on innovation and technology.
    Emerged with the aim of providing quality services in logistics, in a simple, agile and safe way.</p>

  <p>We have an easy-to-use and reliable logistics system, ensuring efficient deliveries.</p>

  <p>Currently only work with companies, assisting in the export of products from the United States to Brazil.</p>

  <span class="chamada-home-2 text-center mt-5">Come add value to your business with quality service!</span>

  <div class="text-center mt-4">
    <button aria-label="Get in touch" class="btn btn-default" routerLink="/contact">Get in touch</button>
  </div>
</div>
