<div (click)="closeMessage()" *ngIf="message" class="alert alert-dismissible alert-warning">
  <button aria-label="Close" class="btn-close" data-dismiss="alert" type="button">
    <span class="visually-hidden">&times;</span>
  </button>
  <span [innerHTML]="message"></span>
</div>

<div *ngIf="event?.eventos" class="table-responsive">
  <table aria-describedby="title" class="table">
    <thead>
    <tr>
      <th scope="col">Event Locality</th>
      <th scope="col">City (State)</th>
      <th scope="col">Description</th>
      <th class="date" scope="col">Event Date</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let i of event?.eventos">
      <td>{{i.unidade?.tipo | emptyValue}}</td>
      <td>{{i.unidade?.endereco?.cidade | emptyValue}}
        <span *ngIf="i.unidade?.endereco?.uf">({{i.unidade?.endereco?.uf}})</span>
      </td>
      <td>{{i.descricao | emptyValue}}</td>
      <td>{{i.dtHrCriado | date:'short' | emptyValue}}</td>
    </tr>
    <tr *ngIf="loading">
      <td class="notfound" colspan="8">Loading...</td>
    </tr>
    <tr *ngIf="!event?.eventos?.length && !loading">
      <td class="notfound" colspan="4">No records found.</td>
    </tr>
    </tbody>
  </table>
</div>
