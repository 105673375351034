import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {ShipmentFileModel} from '../../model/shipment-file.model';
import {Observable, of} from 'rxjs';
import {ViacepModel} from '../../model/viacep.model';
import {PageModel} from '../../model/page.model';
import {CompanyModel} from '../../model/company.model';
import {ShipmentPackageModel} from '../../model/shipment-package.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentFileService extends BaseService<ShipmentFileModel, number> {

  constructor(protected http: HttpClient) {
    super(http, '/v1/shipment-files');
  }

  search(filename: string, trackingNumber: string, customerCode: string, fileStatus: string, company: CompanyModel, account: number,
         page: PageModel<ShipmentFileModel>): Observable<PageModel<ShipmentFileModel>> {
    let params = new HttpParams();
    if (filename) {
      params = params.set('filename', filename);
    }
    if (trackingNumber) {
      params = params.set('trackingNumber', trackingNumber);
    }
    if (customerCode) {
      params = params.set('customerCode', customerCode);
    }
    if (fileStatus) {
      params = params.set('fileStatus', fileStatus);
    }
    if (company?.id) {
      params = params.set('companyId', company.id.toString());
    }
    if (account) {
      params = params.set('accountId', account);
    }

    params = this.setPagination(params, page);
    return this.http.get<PageModel<ShipmentFileModel>>(this.baseURL, {params});
  }

  searchPackage(filename: string, trackingNumber: string, customerCode: string, fileStatus: string, company: CompanyModel, account: number,
                page: PageModel<any>): Observable<PageModel<ShipmentPackageModel>> {
    let params = new HttpParams();
    if (filename) {
      params = params.set('filename', filename);
    }
    if (trackingNumber) {
      params = params.set('trackingNumber', trackingNumber);
    }
    if (customerCode) {
      params = params.set('customerCode', customerCode);
    }
    if (fileStatus) {
      params = params.set('fileStatus', fileStatus);
    }
    if (company?.id) {
      params = params.set('companyId', company.id.toString());
    }
    if (account) {
      params = params.set('accountId', account);
    }

    params = this.setPagination(params, page);
    return this.http.get<PageModel<ShipmentPackageModel>>(`${this.baseURL}/packages`, {params});
  }

  getAddressByZipCode(zipcode: string): Observable<ViacepModel> {
    if (!zipcode) {
      return of();
    }

    return this.http.get<ViacepModel>(`https://viacep.com.br/ws/${zipcode.replace('-', '')}/json/`);
  }

  upload(name: string, file: File) {
    const config = {
      headers: {
        'Content-Type': 'text/csv'
      },
      params: {
        filename: file.name
      }
    };

    return this.http.post(`${this.baseURL}/upload`, file, config);
  }
}
