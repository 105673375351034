import {Component} from '@angular/core';
import {faChevronRight, faSearch} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html'
})
export class HomeComponent {

  chevronRight = faChevronRight;
  search = faSearch;

  trackingNumber: string;

}
