<div class="pagina-externa login">
  <div class="col-lg-12 col-md-12 col-sm-12 mt-md-5 mt-sm-1">
    <div *ngIf="!isLoggedIn">
      <form role="form" #f="ngForm">
        <div class="mt-3">
          <label class="form-label" for="email">E-mail</label>
          <input [(ngModel)]="user.email" autocomplete="username" class="form-control" id="email" type="email"
                 #e="ngModel" [ngClass]="{ 'is-invalid': e.errors && e.touched }" appAutoFocus email name="email"
                 required>
          <div *ngIf="e.errors?.email" class="invalid-feedback">
            The e-mail is not valid.
          </div>
        </div>
        <div class="mt-3">
          <label class="form-label" for="password">Password</label>
          <input type="password" class="form-control" id="password" [(ngModel)]="user.password" #password="ngModel"
                 [ngClass]="{ 'is-invalid': password.errors && password.touched }" autocomplete="current-password"
                 name="password" required/>
        </div>
        <div class="d-grid">
          <button
            (click)="onSubmit()" [disabled]="!f.form.valid" aria-label="Sign in"
            class="btn btn-primary btn-block">
            Sign in
          </button>
          <button
            aria-label="New account" class="btn btn-default btn-block mt-2" routerLink="/profile/new"
            type="button">
            Create a new account
          </button>
        </div>
        <div class="mt-3 text-end font-small">
          <a aria-label="Forgot password?" routerLink="/profile/forgot-password">Forgot password?</a>
        </div>
        <div class="mt-3 text-center rc">
          This site is protected by reCAPTCHA and the Google
          <a href="https://policies.google.com/privacy">Privacy Policy</a> and
          <a href="https://policies.google.com/terms">Terms of Service</a> apply.
        </div>
      </form>
    </div>
  </div>
</div>
