<div class="pagina-externa tracking">
  <h1 id="title">
    Package tracking
  </h1>
  <div>
    <form #form="ngForm" role="form">
      <div class="row mb-3">
        <div class="col-lg-3 col-md-6 col-sm-12">
          <input #t="ngModel" [(ngModel)]="trackingNumber" [ngClass]="{ 'is-invalid': t.errors && t.touched }"
                 class="form-control" maxlength="13" name="trackingNumber" placeholder="NX123456789BR"
                 required type="text">
        </div>
        <div class="col-lg-3 col-md-6 col-sm-12">
          <button
            (click)="search()" [disabled]="form.invalid" aria-label="Search"
            class="btn btn-default btn-search">
            Search
          </button>
        </div>
      </div>
    </form>
  </div>

  <app-tracking-detail [trackingNumber]="tracking"></app-tracking-detail>
</div>
