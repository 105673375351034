import {Component, OnDestroy, OnInit} from '@angular/core';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
import {TransactionService} from '../../services/base/transaction.service';
import {take} from 'rxjs/operators';
import {MessageService} from '../../services/messages/message.service';
import {Subscription} from 'rxjs';
import {TokenStorageService} from '../../services/auth/token-storage.service';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html'
})
export class BalanceComponent implements OnDestroy, OnInit {

  balance: number;
  chevronDown = faChevronDown;
  transactionChange: Subscription;

  constructor(
    private readonly transactionService: TransactionService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly messageService: MessageService
  ) {
    this.loadBalance();
  }

  private loadBalance(): void {
    if (this.tokenStorageService.getToken()) {
      this.transactionService.getBalance().pipe(
        take<number>(1)
      ).subscribe({
        next: balance => {
          this.balance = balance;

          setTimeout(() => {
            this.loadBalance();
          }, 5000);
        },
        error: error => this.messageService.addDanger(error)
      });
    }
  }

  ngOnInit() {
    this.transactionChange = TransactionService.transactionChange.subscribe({
      next: () => this.loadBalance()
    });
  }

  ngOnDestroy() {
    if (this.transactionChange) {
      this.transactionChange.unsubscribe();
    }
  }
}
