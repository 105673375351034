import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {ShipmentPackageModel} from '../../model/shipment-package.model';
import {Observable} from 'rxjs';
import {PackageStatisticsModel} from '../../model/package-statistics.model';
import {PacketPackageModel} from '../../model/packet/packet-package.model';

@Injectable({
  providedIn: 'root'
})
export class ShipmentPackageService extends BaseService<ShipmentPackageModel, number> {

  constructor(protected http: HttpClient) {
    super(http, '/v1/shipment-packages');
  }

  findFreeByTrackingCode(trackingCode: string): Observable<ShipmentPackageModel> {
    return this.http.get<ShipmentPackageModel>(`${this.baseURL}/${trackingCode}/free`);
  }

  findShipmentsToReceive(): Observable<PackageStatisticsModel[]> {
    return this.http.get<PackageStatisticsModel[]>(`${this.baseURL}/receive`);
  }

  notify(id: number): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/${id}/notify`, {});
  }

  resend(id: number): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/${id}/resend`, {});
  }

  findCorreiosById(id: number) : Observable<PacketPackageModel>{
    return this.http.get<PacketPackageModel>(`${this.baseURL}/${id}/correios`);
  }
}
