import {Component, OnInit} from '@angular/core';
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {TokenStorageService} from '../../../shared/services/auth/token-storage.service';
import {PriceService} from '../../../shared/services/base/price.service';
import {HttpUtils} from '../../../shared/http-utils';
import {take} from 'rxjs/operators';
import {PricesModel} from '../../../shared/model/prices.model';
import {MessageService} from '../../../shared/services/messages/message.service';
import {PriceCategoryModel} from '../../../shared/model/price-category.model';
import {Utils} from '../../../shared/utils';

class DistributionModalities {
  id: number;
  name: string;
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {

  isLoggedIn = false;

  plus = faPlus;

  distributionModalities: DistributionModalities[] = [];

  prices: PricesModel[] = [];

  constructor(
    readonly tokenStorageService: TokenStorageService,
    private readonly priceService: PriceService,
    private readonly messageService: MessageService
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();
  }

  public ngOnInit(): void {
    HttpUtils.catchError(this.messageService, this.priceService.listPrices()).pipe(
      take<PricesModel[]>(1)
    ).subscribe({
      next: prices => {
        this.prices = prices;
        this.distributionModalities = Utils.getDistinct(prices.map(x => ({
          id: x.category.distributionModality,
          name: x.category.distributionModalityName.toLowerCase()
        } as DistributionModalities)), 'name');
      },
      error: error => this.messageService.addDanger(error)
    });
  }

  public getRangeDescription(category: PriceCategoryModel): string {
    if (category.kiosk) {
      return `Balcony or kiosk`;
    } else if (category.pickup) {
      return `Pickup`;
    } else if (!category.minQuantity) {
      return `Up to ${category.maxQuantity}`;
    } else if (!category.maxQuantity) {
      return `More than ${category.minQuantity}`;
    }

    return `From ${category.minQuantity} to ${category.maxQuantity}`;
  }

  public getPrices(distributionModality: number): PricesModel[] {
    return this.prices.filter(x => x.category.distributionModality === distributionModality);
  }

  public getColumns(prices: PricesModel[]): number[][] {
    const rows: number[][] = [];

    if (prices.length > 0 && prices[0].prices.length > 0) {

      for (let y = 0; y < prices[0].prices.length; y++) {
        const row: number[] = [];
        row.push(prices[0].prices[y].maxWeight);

        for (const item of prices) {
          if (item?.prices?.[y]?.value) {
            row.push(item.prices[y].value);
          }
        }

        rows.push(row);
      }
    }

    return rows;
  }
}
