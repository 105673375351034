import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TableModule} from 'primeng/table';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {PaginatorModule} from 'primeng/paginator';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import {EmptyValuePipe} from './pipes/empty-value.pipe';
import {AutoFocusDirective} from './directives/autofocus.directive';
import {ThemeBarComponent} from './components/theme-bar/theme-bar.component';
import {TrackingDetailComponent} from './components/tracking-detail/tracking-detail.component';
import {TrackingDialogComponent} from './components/tracking-dialog/tracking-dialog.component';
import {SortingComponent} from './components/sorting/sorting.component';
import {RouterModule} from '@angular/router';
import {MultiSelectModule} from 'primeng/multiselect';
import {Cn38DetailComponent} from './components/cn38-detail/cn38-detail.component';
import {CalendarModule} from 'primeng/calendar';
import {BalanceComponent} from './components/balance/balance.component';
import {CreditHistoryComponent} from './components/credit-history/credit-history.component';
import {AddFundsDialogComponent} from './components/add-funds-dialog/add-funds-dialog.component';
import {CreditCardDirectivesModule} from 'angular-cc-library';
import {RecaptchaV3Module} from 'ng-recaptcha';
import {ShipmentItemComponent} from './components/shipment-item/shipment-item.component';

@NgModule({
  declarations: [
    EmptyValuePipe,
    AutoFocusDirective,
    ThemeBarComponent,
    BreadcrumbComponent,
    TrackingDetailComponent,
    TrackingDialogComponent,
    Cn38DetailComponent,
    SortingComponent,
    BalanceComponent,
    CreditHistoryComponent,
    AddFundsDialogComponent,
    ShipmentItemComponent
  ],
  imports: [
    CommonModule,
    TableModule,
    InputNumberModule,
    PaginatorModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    InputMaskModule,
    FontAwesomeModule,
    MultiSelectModule,
    RouterModule,
    CalendarModule,
    CreditCardDirectivesModule,
    RecaptchaV3Module
  ],
  exports: [
    CommonModule,
    FormsModule,
    TableModule,
    InputNumberModule,
    PaginatorModule,
    MultiSelectModule,
    NgbModule,
    RouterModule,
    ReactiveFormsModule,
    InputMaskModule,
    FontAwesomeModule,
    CreditCardDirectivesModule,
    EmptyValuePipe,
    AutoFocusDirective,
    ThemeBarComponent,
    TrackingDetailComponent,
    TrackingDialogComponent,
    Cn38DetailComponent,
    SortingComponent,
    BalanceComponent,
    BreadcrumbComponent,
    CreditHistoryComponent,
    AddFundsDialogComponent,
    ShipmentItemComponent,
    RecaptchaV3Module
  ]
})
export class SharedModule {
}
