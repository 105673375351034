import {Component} from '@angular/core';
import {MessageService} from '../../../shared/services/messages/message.service';
import {ShipmentFileService} from '../../../shared/services/base/shipment-file.service';
import {Router} from '@angular/router';
import {TokenStorageService} from '../../../shared/services/auth/token-storage.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrls: ['./authenticated.component.scss']
})
export class AuthenticatedComponent {

  trackingNumber: string;
  tracking: string;

  constructor(
    private readonly messageService: MessageService,
    private readonly router: Router,
    private readonly shipmentFileService: ShipmentFileService,
    private readonly tokenStorageService: TokenStorageService
  ) {
  }

  search() {
    // Force reloading.
    this.tracking = null;
    setTimeout(() => this.tracking = this.trackingNumber);
  }

  get isAdmin(): boolean {
    return this.tokenStorageService.isAdmin();
  }

  onFileSelected(event) {
    this.send(event.target.files[0].name, event.target.files[0]);
  }

  dragEnter(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  dragOver(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();
  }

  drop(e: DragEvent): void {
    e.stopPropagation();
    e.preventDefault();

    if (e.dataTransfer.files.length > 1) {
      this.messageService.addDanger('Please select just one file.');
    } else {
      this.send(e.dataTransfer.files[0].name, e.dataTransfer.files[0]);
    }
  }

  private send(name: string, file: File): void {
    this.shipmentFileService.upload(name, file).pipe(take(1)).subscribe({
      next: () => {
        this.messageService.addSuccess('Shipment file uploaded.');
        this.router.navigate(['/shipment']).then();
      },
      error: error =>
        this.messageService.addDanger(error)
    });
  }
}
