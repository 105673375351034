import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PriceModel} from '../../model/price.model';
import {PricesModel} from '../../model/prices.model';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends BaseService<PriceModel, number> {

  constructor(protected http: HttpClient) {
    super(http, '/public/v1/prices');
  }

  listPrices(): Observable<PricesModel[]> {
    return this.http.get<PricesModel[]>(this.baseURL);
  }
}
