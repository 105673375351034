import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {ClientModel} from '../../model/client.model';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly http: HttpClient
  ) {
  }

  login(username: string, password: string, tokenReCaptcha: string): Observable<any> {
    return this.http.post(`${environment.baseURL}/auth/sign-in`, {username, password, tokenReCaptcha}, httpOptions);
  }

  create(client: ClientModel): Observable<ClientModel> {
    return this.http.post(`${environment.baseURL}/auth`, client, httpOptions);
  }
}
