import {Component, OnDestroy} from '@angular/core';
import {LoaderService} from '../../services/loader.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-my-loader',
  templateUrl: './my-loader.component.html',
  styleUrls: ['./my-loader.component.scss']
})
export class MyLoaderComponent implements OnDestroy {

  loading: boolean;

  private subscription: Subscription;

  constructor(
    private loaderService: LoaderService
  ) {
    this.subscription = this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
