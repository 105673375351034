export class Utils {
  static toDate(value: string | Date): Date {
    if (value == null) {
      return null;
    } else if (value instanceof Date) {
      return value;
    } else if (value.indexOf('T') !== -1) {
      return new Date(value);
    } else {
      return new Date(value + 'T00:00:00');
    }
  }

  static sortField(values: any[], field: string, type: string): any[] {
    return values.sort((n1, n2) => {
      if (!n1[field]) {
        return Utils.orderType(type);
      } else if (!n2[field]) {
        return Utils.orderType(type, true);
      }
      if (!isNaN(Number(n1[field])) && !isNaN(Number(n2[field]))) {
        if (Number(n1[field]) > Number(n2[field])) {
          return Utils.orderType(type);
        } else if (Number(n1[field]) < Number(n2[field])) {
          return Utils.orderType(type, true);
        }
      }

      if (n1[field] > n2[field]) {
        return Utils.orderType(type);
      } else if (n1[field] < n2[field]) {
        return Utils.orderType(type, true);
      }

      return 0;
    });
  }

  private static orderType(type: string, inverse: boolean = false): number {
    if (inverse) {
      return type === 'ASC' ? -1 : 1;
    }

    return type === 'ASC' ? 1 : -1;
  }

  public static getDistinct<T>(data: T[], property: string): T[] {
    return data.filter(
      (thing, i, arr) => arr.findIndex(t => t[property] === thing[property]) === i
    );
  }
}
