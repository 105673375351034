import {Injectable} from '@angular/core';
import {TokenStorageService} from '../services/auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LoginRouteGuard {

  constructor(
    private tokenStorageService: TokenStorageService
  ) {
  }

  canActivate(): boolean {
    return this.tokenStorageService.isAuthenticated();
  }
}
