<div [ngClass]="{ 'autenticado': isLoggedIn, 'nao-autenticado': !isLoggedIn, 'pagina-home': isHome}">
  <nav (scroll)="onWindowScroll();" aria-labelledby="logoPackBoo"
       class="navbar navbar-expand-md navbar-dark bg-dark fixed-top"
       style="z-index: 100;">
    <div class="container-fluid">
      <a class="navbar-brand logo" href="#">
        <img
          alt="PackBoo" height="48" id="logoPackBoo" src="assets/logo-sm.png"
          srcset="assets/logo-sm@2x.png 2x" width="55">
        <span>PackBoo</span>
      </a>
      <button
        (click)="toggleCollapse()" [attr.aria-expanded]="collapsed" aria-label="Toggle navigation"
        class="navbar-toggler"
        data-toggle="collapse" type="button">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse animated fadeInDown" id="navbarSupportedContent" [class.show]="collapsed">
        <div *ngIf="isTracking" style="margin: auto"></div>
        <ul *ngIf="!isTracking" class="navbar-nav me-auto mb-2 mb-md-0 menu">
          <li class="nav-item" *ngIf="!isLoggedIn">
            <a (click)="hideCollapse()" [class.active]="isHome" aria-current="page" class="nav-link"
               routerLink="/">Home</a>
          </li>
          <li class="nav-item" *ngIf="!isLoggedIn">
            <a (click)="hideCollapse()" aria-current="page" class="nav-link" routerLink="/about"
               routerLinkActive="active">About us</a>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item" ngbDropdown>
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">Services
              <div ngbDropdownMenu>
                <a (click)="hideCollapse()" [href]="apiDoc" aria-label="Public API" ngbDropdownItem rel="noreferrer"
                   target="_blank">
                  Public API
                </a>
                <div *ngIf="showAdmin || (showUser && user?.hasAffiliates)" class="dropdown-divider"></div>
                <button
                  (click)="hideCollapse()" *ngIf="showUser && user?.hasAffiliates" aria-label="Affiliates"
                  ngbDropdownItem routerLink="/services/affiliates" routerLinkActive="active">
                  Affiliates
                </button>
                <button
                  (click)="hideCollapse()" *ngIf="showAdmin" aria-label="Packet status" ngbDropdownItem
                  routerLink="/services/packet" routerLinkActive="active">
                  Packet status
                </button>
              </div>
            </div>
          </li>
          <li ngbDropdown class="nav-item" *ngIf="showAdmin">
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">
              Financial
              <div ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="Companies" ngbDropdownItem
                  routerLink="/financial/account-credit" routerLinkActive="active">
                  Account credits
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="showAdmin" class="nav-item" ngbDropdown>
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">
              Management
              <div ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="Companies" ngbDropdownItem
                  routerLink="/management/company" routerLinkActive="active">
                  Companies
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Users" ngbDropdownItem
                  routerLink="/management/user" routerLinkActive="active">
                  Users
                </button>
                <div class="dropdown-divider"></div>
                <button
                  (click)="hideCollapse()" aria-label="Countries" ngbDropdownItem
                  routerLink="/management/country" routerLinkActive="active">
                  Countries
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Alert Mail" ngbDropdownItem
                  routerLink="/management/alert-mail" routerLinkActive="active">
                  Alert Mail
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item" ngbDropdown>
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">Shipments
              <div ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="Shipments" ngbDropdownItem
                  [routerLinkActiveOptions]="{exact: true}" routerLink="/shipment" routerLinkActive="active">
                  Shipments
                </button>
                <button
                  (click)="hideCollapse()" *ngIf="user?.accountName" aria-label="New shipment" ngbDropdownItem
                  routerLink="/shipment/new" routerLinkActive="active">
                  New shipment
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="showAdmin" class="nav-item" ngbDropdown>
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">Units
              <div ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="Units" ngbDropdownItem routerLink="/unit"
                  [routerLinkActiveOptions]="{exact: true}" routerLinkActive="active">
                  Units
                </button>
                <button
                  (click)="hideCollapse()" aria-label="New unit" ngbDropdownItem routerLink="/unit/new"
                  routerLinkActive="active">
                  New unit
                </button>
                <div class="dropdown-divider"></div>
                <button
                  (click)="hideCollapse()" aria-label="Departures pending" ngbDropdownItem
                  routerLink="/unit/departures-pending" routerLinkActive="active">
                  Departures pending
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Departures confirmed" ngbDropdownItem
                  routerLink="/unit/departures-confirmed" routerLinkActive="active">
                  Departures confirmed
                </button>
                <div class="dropdown-divider"></div>
                <button
                  (click)="hideCollapse()" aria-label="Return available" ngbDropdownItem
                  routerLink="/unit/return-available" routerLinkActive="active">
                  Return available
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Return confirmed" ngbDropdownItem
                  routerLink="/unit/return-confirmed" routerLinkActive="active">
                  Return confirmed
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="isLoggedIn" class="nav-item" ngbDropdown>
            <div class="nav-link" ngbDropdownToggle routerLinkActive="active">Reports
              <div ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="Shipments" ngbDropdownItem
                  routerLink="/reports/shipment" routerLinkActive="active">
                  Shipments
                </button>
              </div>
            </div>
          </li>
          <li class="nav-item">
            <a (click)="hideCollapse()" aria-current="page" class="nav-link" routerLink="/help"
               aria-label="Help" routerLinkActive="active">
              Help
            </a>
          </li>
          <li class="nav-item">
            <a (click)="hideCollapse()" aria-current="page" class="nav-link" routerLink="/contact"
               routerLinkActive="active">
              Contact
            </a>
          </li>
        </ul>

        <ul *ngIf="!isLoggedIn" class="navbar-nav mb-2 mb-md-0 redes-sociais signin">
          <li *ngIf="social.whatsapp" class="nav-item">
            <a [href]="social.whatsapp" class="whatsapp" ngbTooltip="WhatsApp" rel="noopener" target="_blank"
               aria-label="Whatsapp link" placement="bottom">
              <fa-icon [icon]="whatsapp"></fa-icon>
            </a>
          </li>
          <li *ngIf="social.instagram" class="nav-item">
            <a [href]="social.instagram" class="instagram" ngbTooltip="Instagram" placement="bottom"
               aria-label="Instagram link" rel="noopener" target="_blank">
              <fa-icon [icon]="instagram"></fa-icon>
            </a>
          </li>
          <li *ngIf="social.youtube" class="nav-item">
            <a [href]="social.youtube" class="youtube" ngbTooltip="Youtube"
               aria-label="Youtube link" placement="bottom" rel="noopener" target="_blank">
              <fa-icon [icon]="youtube"></fa-icon>
            </a>
          </li>
          <li *ngIf="social.facebook" class="nav-item">
            <a [href]="social.facebook" class="facebook" ngbTooltip="Facebook" placement="bottom"
               aria-label="Facebook link" rel="noopener" target="_blank">
              <fa-icon [icon]="facebookSquare"></fa-icon>
            </a>
          </li>
          <li *ngIf="social.twitter" class="nav-item">
            <a [href]="social.twitter" class="twitter" ngbTooltip="Twitter" placement="bottom" rel="noopener"
               aria-label="Twitter link" target="_blank">
              <fa-icon [icon]="twitter"></fa-icon>
            </a>
          </li>
        </ul>

        <ul class="navbar-nav mb-2 mb-md-0">
          <li *ngIf="showUser || showPersonal" class="nav-item" ngbDropdown>
            <div>
              <div class="nav-link creditos" ngbDropdownToggle>
                <app-balance></app-balance>
              </div>
              <div class="fix-menu-profile" ngbDropdownMenu>
                <button
                  (click)="addFunds()" aria-label="Add funds" ngbDropdownItem>
                  Add funds
                </button>
                <button
                  (click)="hideCollapse()" aria-label="View history" ngbDropdownItem
                  routerLink="/balance/history" routerLinkActive="active">
                  View history
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Payment method" ngbDropdownItem
                  routerLink="/balance/payment" routerLinkActive="active">
                  Payment method
                </button>
              </div>
            </div>
          </li>
          <li *ngIf="!isTracking" class="nav-item">
            <a (click)="hideCollapse()" *ngIf="!isLoggedIn" aria-current="page" aria-label="Sign in"
               class="nav-link btn btn-login" routerLink="/login">
              Sign in
            </a>
          </li>
          <li class="nav-item menu-usuario" ngbDropdown>
            <div *ngIf="isLoggedIn">
              <div class="nav-link fix-gravatar-size dados-usuario" ngbDropdownToggle>
                <div class="foto-usuario">
                  <img alt="avatar" class="gravatar"
                       size="40" src="//www.gravatar.com/avatar/{{user?.emailHash || ''}}?d=mp&s=40">
                </div>
                <div class="nomes">
                  <div>
                  <span *ngIf="!showPersonal" appIsEllipsisActive class="nome-empresa">
                    {{user.accountName || (showAdmin ? 'ADMINISTRATOR' : '') }}
                  </span>
                    <span
                      *ngIf="unread > 0"
                      class="badge m-badge rounded-pill bg-danger"
                      ngbTooltip="You have {{unread}} unread message{{unread > 1 ? 's': ''}}">
                      {{unread}}
                      <span class="visually-hidden">unread messages</span>
                  </span>
                  </div>
                  <div appIsEllipsisActive class="nome-usuario">{{user.name}}</div>
                </div>
              </div>
              <div class="fix-menu-profile" ngbDropdownMenu>
                <button
                  (click)="hideCollapse()" aria-label="My profile" ngbDropdownItem routerLink="/profile/my"
                  routerLinkActive="active">
                  My profile
                </button>
                <button
                  (click)="hideCollapse()" aria-label="Change password" ngbDropdownItem
                  routerLink="/profile/change-password" routerLinkActive="active">
                  Change password
                </button>
                <div class="dropdown-divider"></div>
                <button
                  aria-label="Messages" class="position-relative" ngbDropdownItem
                  routerLink="/profile/messages" routerLinkActive="active">
                  Messages
                  <span *ngIf="unread > 0"
                        class="badge rounded-pill bg-danger menu-badge position-absolute ">
                    {{unread}}
                  </span>
                </button>
                <div class="dropdown-divider"></div>
                <button (click)="logout()" aria-label="Logout" ngbDropdownItem>Logout</button>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div class="container-fluid">
    <main>
      <app-alert-message></app-alert-message>
      <app-confirm-message></app-confirm-message>
      <app-add-funds-dialog [(display)]="displayAddFunds"></app-add-funds-dialog>
      <router-outlet></router-outlet>
      <app-my-loader></app-my-loader>
    </main>
  </div>

  <footer class="my-3 pt-5 text-muted text-center text-small">
    <p class="mb-1">
      Copyright © 2021 PackBoo {{version}} | All rights reserved | <a routerLink="/terms">Terms and conditions</a>
    </p>
  </footer>

</div>
