import {catchError} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MessageService} from './services/messages/message.service';

export class HttpUtils {

  static catchError<T>(messageService: MessageService, source: Observable<T>): Observable<T> {
    return source.pipe(
      catchError(error => {
        messageService.addDanger(error);
        return of(null);
      })
    );
  }

}
