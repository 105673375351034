import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {MessageService} from './shared/services/messages/message.service';
import {TokenStorageService} from './shared/services/auth/token-storage.service';
import {debounceTime, take, takeLast, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {Subscription} from 'rxjs';
import {UserModel} from './shared/model/user.model';
import {faFacebookSquare, faInstagram, faTwitter, faWhatsapp, faYoutube} from '@fortawesome/free-brands-svg-icons';
import {Router} from '@angular/router';
import {UserSessionService} from './shared/services/auth/user-session.service';
import {UserMessageService} from './shared/services/base/user-message.service';
import {VERSION} from '../version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  displayAddFunds = false;
  unread = 0;
  version = VERSION;
  collapsed = false;
  isLoggedIn = false;
  showPersonal = false;
  showAdmin = false;
  showUser = false;
  username: string;
  user: UserModel;
  apiDoc: string = environment.apiDoc;
  social = {
    facebook: 'https://www.facebook.com/profile.php?id=100075184954068',
    whatsapp: 'https://wa.me/13865074293',
    twitter: 'https://twitter.com/PackBooo',
    instagram: 'https://www.instagram.com/pack_boo/',
    youtube: 'https://www.youtube.com/channel/UCzRww5G9xUB0QzvtUl4GsAA'
  };

  facebookSquare = faFacebookSquare;
  instagram = faInstagram;
  twitter = faTwitter;
  youtube = faYoutube;
  whatsapp = faWhatsapp;
  private subscription: Subscription;
  private messagesSubscription: Subscription;
  private clearMessagesSubscription: Subscription;

  constructor(
    private readonly tokenStorageService: TokenStorageService,
    private readonly messageService: MessageService,
    private readonly userSessionService: UserSessionService,
    private readonly userMessageService: UserMessageService,
    private readonly router: Router
  ) {
    this.messagesSubscription = UserMessageService.messageChange.subscribe(change => this.unread += change);
    this.clearMessagesSubscription = UserMessageService.clearChange.subscribe(clear => {
      if (clear) {
        this.unread = 0;
      } else {
        this.updateMessagesCount();
      }
    });
  }

  ngOnInit(): void {
    window.alert = (message) => this.messageService.addSuccess(message);
    this.tokenStorageService.logEvent.pipe(
      debounceTime(100),
      tap(() => this.login()),
      takeLast(1)
    ).subscribe();

    this.login();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.messagesSubscription.unsubscribe();
    this.clearMessagesSubscription.unsubscribe();
  }

  login(): void {
    this.unread = 0;
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      this.user = this.tokenStorageService.getUser();

      this.showAdmin = this.tokenStorageService.isAdmin();
      this.showUser = this.tokenStorageService.isUser();
      this.showPersonal = this.tokenStorageService.isPersonal();

      this.username = this.user.username;
      this.updateMessagesCount();
      this.startMessagesTimer();
    } else {
      this.clearSession();
    }
  }

  private startMessagesTimer(): void {
    const updateInterval = setInterval(() => {
      if (this.isLoggedIn) {
        this.updateMessagesCount();
      } else {
        clearInterval(updateInterval);
      }
    }, 25000);
  }

  private updateMessagesCount(): void {
    this.userMessageService.countUnreaded().pipe(
      take<number>(1)
    ).subscribe({
      next: count => this.unread = count,
      error: error => this.messageService.addDanger(error)
    });
  }

  logout(): void {
    this.hideCollapse();
    this.clearTheme();

    setTimeout(() => {
      this.user = null;
      this.tokenStorageService.signOut();
      this.clearSession();
      this.router.navigate(['/']).then();
    }, 100);
  }

  clearTheme(): void {
    const body = document.querySelector('body');
    body.classList.remove('tema-branco', 'tema-azul', 'tema-preto');
  }

  private clearSession(): void {
    this.isLoggedIn = false;
    this.showAdmin = false;
    this.showUser = false;
    this.showPersonal = false;
  }

  toggleCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  addFunds(): void {
    this.displayAddFunds = true;
    this.hideCollapse();
  }

  hideCollapse(): void {
    this.collapsed = false;
  }

  get isHome(): boolean {
    return this.router.url === '/';
  }

  get isTracking(): boolean {
    return this.router.url.startsWith('/tracking');
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const element = document.querySelector('.navbar');
    if (window.scrollY > element.clientHeight) {
      element.classList.add('navbar-inverse');
    } else {
      element.classList.remove('navbar-inverse');
    }
  }
}
