<div *ngIf="isLoggedIn" class="col-lg-12">
  <div class="row">
    <div class="col-md-8 col-sm-12 breadcrumb">
      <ng-content></ng-content>
    </div>
    <div class="col-md-4 col-sm-12 text-end temas">
      <app-theme-bar></app-theme-bar>
    </div>
  </div>
</div>
