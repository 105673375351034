<div *ngIf="isLoggedIn" class="text-end temas">
  Theme
  <button
    (click)="changeTheme('tema-branco')" [class.active]="currentTheme == 'tema-branco'"
    aria-label="White theme" class="opcao-tema tema-branco"
    ngbTooltip="White theme" placement="bottom" showDelay="500"><span></span></button>
  <button
    (click)="changeTheme('tema-azul')" [class.active]="currentTheme == 'tema-azul'"
    aria-label="Blue theme" class="opcao-tema tema-azul" ngbTooltip="Blue theme"
    placement="bottom" showDelay="500"><span></span></button>
  <button
    (click)="changeTheme('tema-preto')" [class.active]="currentTheme == 'tema-preto'"
    aria-label="Black theme" class="opcao-tema tema-preto" ngbTooltip="Black theme"
    placement="bottom" showDelay="500"><span></span></button>
</div>
