<div class="pagina-externa home">

  <div class="row">
    <div class="col-xl-7 col-lg-12 col-md-12 col-sm-12 mb-5">
      <h1>NOW IT'S EASIER TO EXPORT!</h1>
      <span class="chamada-home-1">We transport for you!</span>
      <span class="chamada-home-2">
        Delivery service from the United States to all of Brazil
      </span>
    </div>
    <div class="col-xl-5 col-lg-12 col-md-12 col-sm-12">
      <div class="box-track">
        <form #form="ngForm" role="form">
          <div class="row row-cols-lg-auto g-3 align-items-center">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-3">
              <fa-icon [icon]="search" class="search"></fa-icon>
            </div>
            <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-9">
              <h2>Package tracking</h2>
              <input #t="ngModel" [(ngModel)]="trackingNumber" [ngClass]="{ 'is-invalid': t.errors && t.touched }"
                     class="form-control" maxlength="13" name="trackingNumber" placeholder="NX123456789BR" required
                     appAutoFocus type="text">
            </div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-xs-12">
              <button
                [disabled]="form.invalid" [routerLink]="'tracking/' + trackingNumber?.toUpperCase()"
                aria-label="Search" class="btn btn-default">
                Search
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="row mt-5 mb-5 linha-icones">
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 mb-2">
      <div class="card h-100 card-1">
        <div class="card-body">
          <svg height="65.938" viewBox="0 0 82.422 65.938"
               width="82.422" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="linear-gradient-1" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#4097ee"/>
                <stop offset="1" stop-color="#202677"/>
              </linearGradient>
            </defs>
            <path
              d="M80.361,45.332H78.3V31.41a6.182,6.182,0,0,0-1.816-4.366L63.619,14.179a6.182,6.182,0,0,0-4.366-1.816H53.574V6.182A6.183,6.183,0,0,0,47.393,0H14.424A6.183,6.183,0,0,0,8.242,6.182v6.182H1.03A1.033,1.033,0,0,0,0,13.394v2.061a1.033,1.033,0,0,0,1.03,1.03H36.06a1.033,1.033,0,0,1,1.03,1.03v2.061a1.033,1.033,0,0,1-1.03,1.03H5.151a1.033,1.033,0,0,0-1.03,1.03V23.7a1.033,1.033,0,0,0,1.03,1.03H31.938a1.033,1.033,0,0,1,1.03,1.03v2.061a1.033,1.033,0,0,1-1.03,1.03H1.03A1.033,1.033,0,0,0,0,29.878v2.061a1.033,1.033,0,0,0,1.03,1.03H27.817A1.033,1.033,0,0,1,28.848,34V36.06a1.033,1.033,0,0,1-1.03,1.03H8.242V53.574a12.363,12.363,0,0,0,24.727,0H49.453a12.363,12.363,0,0,0,24.727,0h6.182a2.067,2.067,0,0,0,2.061-2.061V47.393A2.067,2.067,0,0,0,80.361,45.332ZM20.605,59.756a6.182,6.182,0,1,1,6.182-6.182A6.183,6.183,0,0,1,20.605,59.756Zm41.211,0A6.182,6.182,0,1,1,68,53.574,6.183,6.183,0,0,1,61.816,59.756Zm10.3-26.787H53.574V18.545h5.679L72.119,31.41Z"
              data-name="Icon awesome-shipping-fast"
              fill="#fff"
              id="Icon_awesome-shipping-fast"/>
          </svg>
          <p class="card-text">Fast delivery</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 mb-2">
      <div class="card h-100 card-2">
        <div class="card-body">
          <svg height="65.938" viewBox="0 0 74.181 65.938"
               width="74.181" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#c721bb"/>
                <stop offset="0.452" stop-color="#8e1886"/>
                <stop offset="0.632" stop-color="#e3b87f"/>
                <stop offset="1" stop-color="#b98d35"/>
              </linearGradient>
            </defs>
            <path
              d="M35.453,32.26a2.243,2.243,0,0,0,3.284,0L52.761,17.554a10.675,10.675,0,0,0-.721-15.3,9.789,9.789,0,0,0-13.509.992L37.088,4.753,35.659,3.259a9.778,9.778,0,0,0-13.509-1,10.686,10.686,0,0,0-.721,15.3ZM72.8,42.254a4.263,4.263,0,0,0-5.486,0l-11.9,9.517a8.2,8.2,0,0,1-5.151,1.8H35.028a2.06,2.06,0,0,1,0-4.121H45.111A4.287,4.287,0,0,0,49.4,46.027,4.126,4.126,0,0,0,45.33,41.21h-20.6A15.157,15.157,0,0,0,15.183,44.6L9.195,49.452H2.06A2.067,2.067,0,0,0,0,51.513V63.876a2.067,2.067,0,0,0,2.06,2.06H48.009a8.252,8.252,0,0,0,5.151-1.8L72.631,48.551A4.119,4.119,0,0,0,72.8,42.254Z"
              data-name="Icon awesome-hand-holding-heart"
              fill="#fff"
              id="Icon_awesome-hand-holding-heart" transform="translate(0 0.002)"/>
          </svg>
          <p class="card-text">Care of products</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 mb-2">
      <div class="card h-100 card-3">
        <div class="card-body">
          <svg height="65.938" viewBox="0 0 57.695 65.938"
               width="57.695" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="linear-gradient-3" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
                <stop offset="0" stop-color="#919191"/>
                <stop offset="0.381" stop-color="#6d6d6d"/>
                <stop offset="0.569" stop-color="#ebb73e"/>
                <stop offset="1" stop-color="#a87602"/>
              </linearGradient>
            </defs>
            <path
              d="M51.514,28.848H48.423V19.575a19.575,19.575,0,0,0-39.15,0v9.272H6.182A6.183,6.183,0,0,0,0,35.029V59.756a6.183,6.183,0,0,0,6.182,6.182H51.514A6.183,6.183,0,0,0,57.7,59.756V35.029A6.183,6.183,0,0,0,51.514,28.848Zm-13.394,0H19.575V19.575a9.272,9.272,0,0,1,18.545,0Z"
              data-name="Icon awesome-lock"
              fill="#fff"
              id="Icon_awesome-lock"/>
          </svg>
          <p class="card-text">Secure process</p>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-12 mb-2">
      <div class="card h-100 card-3">
        <div class="card-body">
          <svg height="65.938" viewBox="0 0 41.211 65.938"
               width="41.211" xmlns="http://www.w3.org/2000/svg">
            <defs>
              <linearGradient id="linear-gradient-4" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0"/>
                <stop offset="1" stop-color="#545454"/>
              </linearGradient>
            </defs>
            <path
              d="M35.029,0H6.182A6.183,6.183,0,0,0,0,6.182V59.756a6.183,6.183,0,0,0,6.182,6.182H35.029a6.183,6.183,0,0,0,6.182-6.182V6.182A6.183,6.183,0,0,0,35.029,0ZM20.605,61.816A4.121,4.121,0,1,1,24.727,57.7,4.117,4.117,0,0,1,20.605,61.816ZM35.029,47.908a1.55,1.55,0,0,1-1.545,1.545H7.727a1.55,1.55,0,0,1-1.545-1.545V7.727A1.55,1.55,0,0,1,7.727,6.182H33.484a1.55,1.55,0,0,1,1.545,1.545Z"
              data-name="Icon awesome-mobile-alt"
              fill="#fff"
              id="Icon_awesome-mobile-alt"/>
          </svg>
          <p class="card-text">Real-time tracking</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-5">
    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
      <button aria-label="Find out more" class="btn btn-default" type="submit">Find out more</button>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <ul class="list-unstyled">
        <li>
          <fa-icon [icon]="chevronRight"></fa-icon>
          We offer competitive rates so you can offer your customers better prices
        </li>
        <li>
          <fa-icon [icon]="chevronRight"></fa-icon>
          Our system is secure and easy to use
        </li>
        <li>
          <fa-icon [icon]="chevronRight"></fa-icon>
          Extraordinary customer service in every step of the way
        </li>
        <li>
          <fa-icon [icon]="chevronRight"></fa-icon>
          Client loyalty and satisfaction is our goal
        </li>
      </ul>
    </div>
  </div>

</div>
