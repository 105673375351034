<app-breadcrumb>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item"><a routerLink="/">Home</a></li>
      <li aria-current="page" class="breadcrumb-item active">Help</li>
    </ol>
  </nav>
</app-breadcrumb>

<div class="pagina-externa ajuda">
  <h1>Help</h1>
  <ngb-accordion>
    <ngb-panel cardClass="card component-tab">
      <ng-template ngbPanelHeader>
        <div class="d-flex align-items-center justify-content-between">
          <button
            aria-label="Price list" class="btn btn-default container-fluid text-start" id="priceTitle"
            ngbPanelToggle>
            Price list
            <fa-icon [icon]="plus"></fa-icon>
          </button>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        Price list based on average monthly volume.

        <ul #myTab="ngbNav" class="nav nav-tabs mt-3" ngbNav>
          <li *ngFor="let dm of distributionModalities; let i = index" [ngbNavItem]="i + 1" class="nav-item">
            <a [innerText]="dm.name" class="nav-link active text-capitalize" id="prices{{i}}" ngbNavLink
               aria-label="Price"
            ></a>
            <ng-template ngbNavContent>
              <div class="card">
                <div class="card-body">
                  <table aria-labelledby="priceTitle" class="table">
                    <thead>
                    <tr>
                      <th scope="col">Weight</th>
                      <th
                        *ngFor="let price of getPrices(i); let i = index"
                        [innerText]="getRangeDescription(price.category)"
                        scope="col"
                      ></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of getColumns(getPrices(i))">
                      <td *ngFor="let column of row; let ic = index">
                        <span *ngIf="ic === 0">{{ column | number: '1.1-1' }}</span>
                        <span *ngIf="ic !== 0">{{ column | currency }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
        </ul>
        <div [ngbNavOutlet]="myTab" style="margin-top: -10px !important;"></div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
