<div #modalConfirm *ngFor="let item of items" [class.visually-hidden]="!display" class="modal fade" role="dialog"
     tabindex="-1">
  <div class="modal-dialog mini-modal" role="document">
    <div class="modal-content">
      <div class="modal-header" *ngIf="item.title">
        <h5 class="modal-title">{{item.title}}</h5>
        <button (click)="cancelMessage(item)" aria-label="Close" class="btn-close" data-dismiss="modal"
                type="button">
          <span class="visually-hidden">&times;</span>
        </button>
      </div>
      <div class="modal-body" [innerHTML]="item.message"></div>
      <div class="modal-footer text-center center">
        <button (click)="cancelMessage(item)" [attr.aria-label]="item.cancelButton" class="btn btn-danger"
                type="button">
          {{item.cancelButton}}
        </button>
        <button
          (click)="confirmMessage(item)" [attr.aria-label]="item.okButton" class="btn btn-primary" type="button">
          {{item.okButton}}</button>
      </div>
    </div>
  </div>
</div>
