import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable, Subject, tap} from 'rxjs';
import {MessageModel} from '../../model/message.model';

@Injectable({
  providedIn: 'root'
})
export class UserMessageService extends BaseService<MessageModel, number> {

  static readonly messageChange = new Subject<number>();
  static readonly clearChange = new Subject<boolean>();

  constructor(protected http: HttpClient) {
    super(http, '/v1/messages');
  }

  countUnreaded(): Observable<number> {
    return this.http.get<number>(`${this.baseURL}/unread`);
  }

  markAllAsRead(): Observable<void> {
    UserMessageService.clearChange.next(true);
    return this.http.put<void>(`${this.baseURL}/readAll`, {}).pipe(
      tap<void>({
        error: () => UserMessageService.clearChange.next(false)
      })
    );
  }

  markAsRead(id: number): Observable<void> {
    UserMessageService.messageChange.next(-1);

    return this.http.put<void>(`${this.baseURL}/${id}/read`, {}).pipe(
      tap<void>({
        error: () => UserMessageService.messageChange.next(1)
      })
    );
  }

  markAsUnread(id: number): Observable<void> {
    UserMessageService.messageChange.next(1);

    return this.http.put<void>(`${this.baseURL}/${id}/unread`, {}).pipe(
      tap<void>({
        error: () => UserMessageService.messageChange.next(-1)
      })
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.baseURL}/${id}`).pipe(
      tap<void>({
        next: () => UserMessageService.clearChange.next(false)
      })
    );
  }

  deleteAll(): Observable<void> {
    return this.http.delete<void>(this.baseURL).pipe(
      tap<void>({
        next: () => UserMessageService.clearChange.next(false)
      })
    );
  }
}
