import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MessageService} from '../../../shared/services/messages/message.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-tracking-number',
  templateUrl: './tracking-number.component.html'
})
export class TrackingNumberComponent implements OnInit, OnDestroy {

  trackingNumber: string;
  tracking: string;
  private subscription: Subscription;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly messageService: MessageService,
    private readonly router: Router
  ) {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit(): void {
    this.subscription = this.activatedRoute.params.subscribe({
      next: params => {
        this.trackingNumber = params.trackingNumber;
        this.tracking = this.trackingNumber;
      },
      error: error => this.messageService.addDanger(error)
    });
  }

  async search(): Promise<void> {
    await this.router.navigate(['/tracking', this.trackingNumber]);
  }
}
