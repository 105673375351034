import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MessageService} from '../../services/messages/message.service';
import {CurrencyPipe} from '@angular/common';
import {CardInfoService} from '../../services/base/card-info.service';
import {take} from 'rxjs/operators';
import {CardInfoModel} from '../../model/card-info.model';
import {PageModel} from '../../model/page.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FundsModel} from '../../model/funds.model';
import {TransactionService} from '../../services/base/transaction.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';

@Component({
  selector: 'app-add-funds-dialog',
  templateUrl: './add-funds-dialog.component.html'
})
export class AddFundsDialogComponent {

  readonly newCard: CardInfoModel = {
    id: 0,
    name: 'Enter new card...'
  };

  name = new FormControl(null, Validators.required);
  number = new FormControl(null, Validators.required);
  cvv = new FormControl(null, Validators.required);
  expiration = new FormControl(null, Validators.required);
  defaultCard = new FormControl(null);
  cardForm: FormGroup;
  display$: boolean;
  value: number;
  cvc: string;
  idCard: number;
  cards: CardInfoModel[] = [this.newCard];

  @ViewChild('modal')
  modal: ElementRef;

  @Output()
  displayChange = new EventEmitter<boolean>();

  constructor(
    readonly messageService: MessageService,
    private readonly formBuilder: FormBuilder,
    private readonly cardInfoService: CardInfoService,
    private readonly recaptchaV3Service: ReCaptchaV3Service
  ) {
    this.cardForm = this.formBuilder.group({
      name: this.name,
      number: this.number,
      cvv: this.cvv,
      expiration: this.expiration,
      defaultCard: this.defaultCard
    });
  }

  @Input()
  set display(value: boolean) {
    this.display$ = value;

    if (this.modal && value) {
      this.modal.nativeElement.style.display = 'block';
      this.cardInfoService.findPaged().pipe(
        take<PageModel<CardInfoModel>>(1)
      ).subscribe({
        next: cards => {
          this.cardForm.reset();
          this.value = null;
          this.cvc = null;
          this.idCard = null;

          this.cards = [this.newCard, ...cards.content.map(c => ({
            ...c,
            name: `${c.name} - ${c.type} ending with ${c.number}`
          }))];

          const defaultPayment = cards.content.find(c => c.defaultCard);
          if (defaultPayment) {
            this.idCard = defaultPayment.id;
          }
          this.modal.nativeElement.classList.add('show');
        },
        error: error => this.messageService.addDanger(error)
      });
    }

    if (this.modal) {
      if (!value) {
        this.modal.nativeElement.classList.remove('show');
        setTimeout(() => {
          this.modal.nativeElement.style.display = 'none';
        }, 100);
      }
    }
  }

  close(): void {
    this.display$ = false;
    this.displayChange.emit(this.display$);
  }

  purchase(): void {
    const currency = new CurrencyPipe('en-US');
    const value = currency.transform(this.value);
    this.messageService.confirm(`Please confirm you wish to add ${value} to your account balance.<br/>` +
      'Once you click the Confirm button, the amount will be added to your account and cannot be refunded.',
      'Confirm transaction', () => {
        const funds: FundsModel = {
          cardId: this.idCard,
          cvv: this.cvc || this.cvv.value,
          value: this.value,
          cardInfo: this.cardForm.value
        };

        this.recaptchaV3Service.execute('card').pipe(
          take(1)
        ).subscribe({
          next: token => {
            funds.token = token;
            this.cardInfoService.addFunds(funds).pipe(
              take(1)
            ).subscribe({
              next: () => {
                this.close();
                TransactionService.transactionChange.next();
                this.messageService.addSuccess(`Your fund request for ${value} has been approved.`);
              },
              error: error => this.messageService.addDanger(error)
            });
          },
          error: (error) => this.messageService.addDanger(error)
        });
      }, null, 'Confirm purchase');
  }

  isExpirationValid(): boolean {
    const expiration = (this.expiration.value || '').trim();
    return (expiration.length === 7 && Number(expiration.substring(5)) >= new Date().getFullYear() % 100)
      || (expiration.length === 9 && Number(expiration.substring(5)) >= new Date().getFullYear());
  }

  changeCard(): void {
    if (this.idCard !== 0) {
      this.cvc = null;
      this.cardForm.reset();
    }
  }
}
