import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../shared/services/auth/auth.service';
import {TokenStorageService} from '../../shared/services/auth/token-storage.service';
import {UserModel} from '../../shared/model/user.model';
import {MessageService} from '../../shared/services/messages/message.service';
import {Router} from '@angular/router';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit {

  isLoggedIn = false;
  isLoginFailed = false;
  roles: string[] = [];
  user: UserModel;

  constructor(
    private readonly authService: AuthService,
    private readonly tokenStorageService: TokenStorageService,
    private readonly messageService: MessageService,
    private readonly recaptchaV3Service: ReCaptchaV3Service,
    private readonly router: Router
  ) {
  }

  ngOnInit(): void {
    this.user = new UserModel();
    this.user.email = '';
    if (this.tokenStorageService.getToken()) {
      this.isLoggedIn = true;
      this.roles = this.tokenStorageService.getUser().roles;
    }
  }

  onSubmit(): void {
    this.recaptchaV3Service.execute('login').pipe(
      take(1)
    ).subscribe({
      next: (token) => {
        this.authService.login(this.user.email, this.user.password, token).subscribe({
          next: data => {
            this.tokenStorageService.saveToken(data.token);
            this.tokenStorageService.saveUser(data);
            this.isLoginFailed = false;
            this.isLoggedIn = true;
            this.roles = this.tokenStorageService.getUser().roles;

            this.router.navigate(['home']).then();
          }, error: error => {
            if (error.status === 401) {
              this.messageService.addDanger('Bad credentials. Please, tray again.');
            } else {
              this.messageService.addDanger(error);
              this.isLoginFailed = true;
            }
          }
        });
      },
      error: (error) => this.messageService.addDanger(error)
    });
  }
}
