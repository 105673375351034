import {Injectable} from '@angular/core';

const PAGE_JOINING_KEY = 'PAGE_JOINING';
const PAGE_SIZE_KEY = 'PAGE_SIZE';
const USER_THEME_KEY = 'USER_THEME';
const REPRESENTATIVE_KEY = 'REPRESENTATIVE';
const SHIPMENT_LISTING_KEY = 'SHIPMENT_LISTING';

@Injectable({
  providedIn: 'root'
})
export class UserSessionService {

  get pageSize(): string {
    return window.localStorage.getItem(PAGE_SIZE_KEY) || 'Letter';
  }

  set pageSize(pageSize: string) {
    window.localStorage.setItem(PAGE_SIZE_KEY, pageSize);
  }

  get pageJoining(): string {
    return window.localStorage.getItem(PAGE_JOINING_KEY) || '1';
  }

  set pageJoining(joining: string) {
    window.localStorage.setItem(PAGE_JOINING_KEY, joining);
  }

  get theme(): string {
    return window.localStorage.getItem(USER_THEME_KEY) || 'tema-branco';
  }

  set theme(theme: string) {
    window.localStorage.setItem(USER_THEME_KEY, theme);
  }

  get representative(): string {
    return window.localStorage.getItem(REPRESENTATIVE_KEY) || '';
  }

  set representative(representative: string) {
    window.localStorage.removeItem(REPRESENTATIVE_KEY);
    window.localStorage.setItem(REPRESENTATIVE_KEY, representative);
  }

  get shipmentListing() {
    return window.localStorage.getItem(SHIPMENT_LISTING_KEY) || 's';
  }

  set shipmentListing(listing: string) {
    window.localStorage.removeItem(SHIPMENT_LISTING_KEY);
    window.localStorage.setItem(SHIPMENT_LISTING_KEY, listing);
  }
}
