import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {Observable} from 'rxjs';
import {PageModel} from '../../model/page.model';
import {CardInfoModel} from '../../model/card-info.model';
import {FundsModel} from '../../model/funds.model';

@Injectable({
  providedIn: 'root'
})
export class CardInfoService extends BaseService<CardInfoModel, number> {

  constructor(protected http: HttpClient) {
    super(http, '/v1/cards-info');
  }

  save(country: CardInfoModel): Observable<CardInfoModel> {
    if (country.id) {
      return this.update(country);
    }

    return this.create(country);
  }

  search(page: PageModel<CardInfoModel>): Observable<PageModel<CardInfoModel>> {
    const params = this.setPagination(new HttpParams(), page);
    return this.http.get<PageModel<CardInfoModel>>(this.baseURL, {params});
  }

  setDefaultCard(id: number): Observable<void> {
    return this.http.put<void>(`${this.baseURL}/${id}/default`, {});
  }

  addFunds(funds: FundsModel): Observable<void> {
    return this.http.post<void>(`${this.baseURL}/credit`, funds);
  }
}
