import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {TokenStorageService} from '../services/auth/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class PublicRouteGuard {

  constructor(
    private tokenStorageService: TokenStorageService,
    private router: Router
  ) {
  }

  canActivate(): boolean {
    if (this.tokenStorageService.isAuthenticated()) {
      this.router.navigate(['home']).then();
    }

    return !this.tokenStorageService.isAuthenticated();
  }
}
