import {Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {MessageConfirm} from '../../services/messages/message';
import {MessageService} from '../../services/messages/message.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-confirm-message',
  templateUrl: './confirm-message.component.html'
})
export class ConfirmMessageComponent implements OnDestroy {

  display: boolean;

  public items: MessageConfirm[] = [];

  private subscription: Subscription;

  @ViewChild('modalConfirm')
  modal: ElementRef;

  constructor(private messageService: MessageService) {
    this.subscription = messageService.messageConfirmEventEmitter.subscribe(message => {
      this.items.push(message);

      setTimeout(() => {
        this.display = true;
        if (this.modal) {
          this.modal.nativeElement.style.display = 'block';
          setTimeout(() => {
            this.modal.nativeElement.classList.add('show');
          }, 100);
        }
      }, 100);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteMessage(item: MessageConfirm) {
    this.display = false;
    this.modal.nativeElement.classList.remove('show');
    setTimeout(() => {
      this.modal.nativeElement.style.display = 'none';

      setTimeout(() => {
        const index = this.items.indexOf(item, 0);
        if (index > -1) {
          this.items.splice(index, 1);
        }
      }, 100);
    }, 100);
  }

  confirmMessage(message: MessageConfirm) {
    if (message.callbackOk) {
      message.callbackOk(message);
    }

    this.deleteMessage(message);
  }

  cancelMessage(message: MessageConfirm) {
    if (message.callbackCancel) {
      message.callbackCancel(message);
    }

    this.deleteMessage(message);
  }
}
